@import "colors";
@import "helpers/button";
@import "helpers/grower";

@mixin desktop {
  @media screen and (min-width: 751px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 750px) {
    @content;
  }
}

.people-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  gap: 1em;
}

.person-card {
  background: $pale;
  border-radius: 0 0 4px 4px;

  display: flex;
  flex-direction: column;

  color: black;
  text-decoration: none;

  border: 1px solid $grey;
  box-shadow: 0 2px 8px $mid-grey;

  .card-header {
    padding-left: 1.6em;
    padding-right: 1.6em;
    padding-top: 1em;
    padding-bottom: 1em;

    h1,
    h2,
    h3,
    h4 {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .card-rest {
    display: flex;
    flex-direction: row;
  }

  .card-image {
    padding: 0;
    margin: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .card-footer {
    padding-left: 1.6em;
    padding-right: 1.6em;
    padding-top: 0.6em;
    padding-bottom: 0.6em;
  }
}

@include desktop {
  .person-card {
    .card-header {
      border-left: 4px solid $primary;
      border-right: 4px solid $primary;
      border-bottom: 1px solid $grey;
    }

    .card-image {
      max-width: 250px;
      max-height: 250px;
      border-right: 1px solid $grey;
    }
  }
}

@include mobile {
  .people-list {
    grid-template-columns: 1fr;
  }

  .person-card {
    border-top: 4px solid $primary;

    .card-header {
      padding-top: 0.5em;
      padding-bottom: 0.5em;
    }

    .card-image {
      max-height: 300px;
    }

    .card-rest {
      flex-direction: column;
    }
  }
}
