@import "colors";
@import "header";
@import "footer";
@import "helpers/button";
@import "helpers/grower";

@import "shortcodes";

@import "form";
@import "navigation";
@import "listing";
@import "people";
@import "columns";
@import "toast";

@import url("https://fonts.googleapis.com/css?family=Work+Sans:400,500,600|Montserrat:400,400i,500&display=swap");

html {
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100%;

  scroll-behavior: smooth;
}

body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

#app {
  background: url("../images/theme/pipes.png");

  position: relative;
  padding: 0;
  margin: 0;
  max-width: 100%;
  min-height: 100%;

  display: flex;
  flex-direction: column;

  font-family: "Work Sans", sans-serif;

  h1,
  h2 {
    letter-spacing: 0.01em;
    word-spacing: 0.15em;
    line-height: 1.6em;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Montserrat", serif;
  }
}

main {
  flex: 1 1 auto;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    &:empty {
      display: none;
    }
  }

  h1 {
    font-size: 1.8em;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.2em;
  }

  h4 {
    font-size: 1em;
  }

  h1,
  h2,
  h3,
  h4 {
    &:target:before {
      display: block;
      content: "";
      height: 5em;
      margin-top: -5em;
    }

    a {
      color: black;
      text-decoration: none;
    }
  }

  p + p {
    margin-top: 1.6em;
  }
}

.main {
  flex: 1 1 auto;
  width: 100%;
}

.main-center {
  flex: 1 1 auto;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.main-content {
  flex: 1 1 auto;
  width: 100%;
  box-sizing: border-box;

  position: relative;

  background: white;
  padding-top: 1.4em;
  padding-bottom: 1.4em;
  padding-left: 1.8em;
  padding-right: 1.8em;

  box-shadow: 0 0 10px $pale;

  .selected {
    text-decoration: underline;

    &:after {
      content: "⇒";
      text-decoration: none;

      position: absolute;
      left: 0;
    }
  }
}

@media screen and (min-width: 900px) {
  .main-content {
    max-width: 1000px;

    .selected {
      &::before {
        left: -1.2em;
      }
    }
  }
}

a {
  color: #555;

  &:focus {
    outline: none;
  }
  &:focus-visible {
    box-shadow: 0 0 0 2px $dark-grey !important;
  }

  &.hoverable {
    position: relative;
    &:after {
      content: "";
      width: 100%;
      height: 2px;

      position: absolute;
      bottom: -2px;
      left: 0;
      display: block;

      background: $dark-grey;

      transform: scaleX(0);

      transition: transform 0.1s ease-in-out;
    }

    &:hover {
      &:after {
        transform: scaleX(1);
      }
    }
  }
}

.button {
  @include grower;
  @include button;
  &.danger {
    @include danger;
  }
}

@each $pos in ("left", "center", "right") {
  .button-joiner-#{$pos} {
    @include button-joiner;
    &.danger {
      @include danger;
    }
  }
}
.button-joiner-left {
  @include button-joiner-left;
}
.button-joiner-right {
  @include button-joiner-right;
}
.button-joiner-center {
  @include button-joiner-center;
}

.text {
  font-size: 1.1em;
  line-height: 1.5em;
  letter-spacing: 0.02em;
}

div.highlight {
  pre {
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    overflow-x: scroll;
  }
}

li {
  p {
    margin: 0;
  }

  p + p {
    margin-top: 0.8em !important;
  }
}

img {
  max-width: 100%;
}

hr {
  margin-top: 1em;
  margin-bottom: 1em;
}

pre {
  line-height: 1.5em;
}

.inline-links {
  text-align: center;
  margin: 2em auto;
  padding: 0.5em;
  max-width: 500px;
  border-top: 2px solid $primary-darker;
  border-bottom: 2px solid $primary-darker;
}

.links {
  margin: 0;
  padding: 0;
  list-style-type: none;

  .link {
    display: inline-block;
    position: relative;
    color: $primary-darkest;
    font-weight: 500;
    margin-left: 1em;

    &:hover {
      .links-hover-menu {
        display: block;
      }
    }

    a {
      text-decoration: none;
      color: inherit;
    }
    &.active {
      > a {
        color: inherit;
        font-weight: 600;
      }
    }
  }

  .link .links-hover-menu {
    display: none;
    position: absolute;

    min-width: max-content;
    padding-top: 0.5em;

    .links-hover-menu-links {
      display: inline-block;
      text-align: left;

      border: 1px solid $dark-grey;
      padding: 1em;
      background-color: $pale;

      &:empty {
        display: none !important;
      }

      .link {
        display: block;
        margin-left: 0;
      }
      .link + .link {
        margin-top: 0.5em;
      }
    }
  }
}

.token {
  font-family: monospace;
  font-size: 1.1em;

  border: 1px solid $mid-grey;
  background-color: #00000010;
  padding: 0.2rem 0.5rem;

  &:hover {
    cursor: pointer;
  }

  position: relative;
  &:after {
    position: absolute;
    top: 0.2rem;
    right: 0;
    transform: translateX(120%) translateY(10px);
    font-family: "Work Sans", sans-serif;
    font-size: 1rem;
    content: "Copied!";
    color: $dark-grey;

    opacity: 0;
    transition: 500ms opacity, 500ms transform;
  }

  &.copied {
    &:after {
      opacity: 1;
      transition: 100ms opacity, 100ms transform;
      transform: translateX(120%) translateY(0);
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinner {
  animation: spin 1s linear infinite;
}

@each $alignment in ("left", "center", "right") {
  .text-align-#{$alignment} {
    text-align: #{$alignment};
  }
}
