@import "colors";
@import "helpers/button";
@import "helpers/grower";

@mixin desktop {
  @media screen and (min-width: 1001px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 1000px) {
    @content;
  }
}

.listing {
  display: grid;
  grid-template-columns: 1fr;

  width: 90%;
  max-width: 1000px;

  margin-left: auto;
  margin-right: auto;

  .listing-item {
    background: $pale;

    display: flex;
    flex-direction: column;
    position: relative;

    padding-top: 16px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 32px;

    color: black;
    text-decoration: none;

    box-shadow: 0 2px 8px $mid-grey;

    .action {
      position: absolute;
      right: 16px;
      top: 16px;
      cursor: pointer;
    }

    .title-container {
      display: flex;
      align-items: baseline;

      .title {
        flex: 1 1 auto;
        margin-left: 16px;
        line-height: 1.5em;
      }

      .icon {
        flex: 0 0 auto;
      }
    }

    .content {
      flex: 1 1 auto;
    }

    .dates {
      color: #555;
      font-style: italic;
      font-size: 0.8em;
      text-align: right;

      margin-top: 20px;
    }
  }
}

@include desktop {
  .listing {
    @for $i from 1 through 3 {
      &.listing-#{$i}-cols {
        grid-template-columns: repeat($i, 1fr);
      }
    }
    gap: 20px;

    .listing-item {
      border: 1px solid $grey;
      border-top: 4px solid $primary;
      border-radius: 0 0 4px 4px;
    }
  }
}

@include mobile {
  .listing {
    gap: 12px;
    .listing-item {
      border: 1px solid $grey;
      border-left: 4px solid $primary;
      border-radius: 0 4px 4px 0;
    }
  }
}

.pagination {
  text-align: center;

  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;

  margin-top: 2em;
  margin-bottom: 2em;

  .page-item {
    list-style: none;
    display: inline;

    .page-link {
      @include button;
      @include grower;

      text-decoration: none;
      font-weight: normal;

      margin-left: 0.4em;
      margin-right: 0.4em;
      margin-top: 0.4em;
      margin-bottom: 0.4em;

      padding-top: 0.5em;
      padding-bottom: 0.5em;
      padding-left: 0;
      padding-right: 0;

      text-align: center;
      width: 2em;

      &[aria-label="First"] {
        display: none;
      }
      &[aria-label="Last"] {
        display: none;
      }
    }

    &.active {
      .page-link {
        font-weight: bold;
      }
    }

    &.disabled {
      filter: grayscale(100%);
    }
  }
}
