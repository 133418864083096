@import "colors";
@import "helpers/button";
@import "helpers/grower";
@import "helpers/messages";

.message {
  @include message;
}

.form {
  width: 100%;
  line-height: 2.5em;

  display: flex;
  flex-direction: column;
  
  h1, h2, .title {
    margin-top: 0;
    margin-bottom: 0.5em;
    font-family: "Montserrat", serif;
    font-weight: bold;
    font-size: 1.8em;
  }

  label {
    display: block;
    font-size: 95%;
  }
  input,
  select,
  textarea {
    outline: none;
    font-family: inherit;
    font-size: 100%;

    &.danger {
      @include danger;
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  textarea,
  select {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 10px;

    background-color: white;

    border: 2px $primary solid;
    transition: border 200ms;
    &:focus {
      border: 2px solid $primary-darkest;
    }
  }

  input[type="file"]::file-selector-button {
    @include grower;
    @include button;
    padding: 0.4em 1em;
    margin: 0.5em;
  }

  button, input[type="submit"] {
    @include grower;
    @include button;
    padding: 0.6em 2em;
    margin-bottom: 0;
    &:focus-visible {
      box-shadow: 0 0 0 2px $dark-grey !important;
    }
  }
  button {
    padding: 0.3em 0.6em;
    width: fit-content;
  }

  textarea {
    resize: vertical;
  }

  .controls {
    margin-top: 0.4em;
    text-align: right;
  }
  
  .form-item {
    width: 100%;
  }
  .form-item + * {
    margin-top: 0.6em;
  }
}

.form-box {
  @extend .form;

  position: relative;
  h1, h2, .title {
    position: absolute;
    top: 0.8em;
  }

  border: 5px solid $primary;
  border-radius: 30px;

  padding-top: 6.5em;
  padding-bottom: 20px;
  padding-left: 50px;
  padding-right: 50px;

  box-sizing: border-box;

  // HACK: avoid singular white band
  background: linear-gradient(180deg, $primary 0%, $primary 5.5em, #ffff 0%);
  background-size: 100% 105%;
  margin: 5% 0;

  max-width: 700px;
}

.input-container {
  $icon-offset: 2.5em;
  $icon-inner-offset: 0.25em;
  display: inline-block;
  position: relative;
  width: 100%;

  .has-icon-left {
    padding-left: $icon-offset !important;
  }
  .has-icon-right {
    padding-right: $icon-offset !important;
  }

  .icon-left,
  .icon-right {
    z-index: 100;
    box-sizing: border-box;
    width: $icon-offset;
    height: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    
    &.clickable {
      cursor: pointer;
      pointer-events: all;
    }
  }
  .icon-left {
    left: 0;
    padding-left: $icon-inner-offset;
  }
  .icon-right {
    right: 0;
    padding-right: $icon-inner-offset;
  }
}
