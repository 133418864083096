$primary: #b1df84;
$primary-darker: #619928;
$primary-dark: #6b8a50;
$primary-darkest: #354f28;
$primary-light: #d5efba;

$pale: #f7f4ed;

$grey: #e4e4e4;
$mid-grey: #c5c5c5;
$dark-grey: #4e4e4e;

$error: #f26350;
$error-light: #e38578;
$error-dark: darken($error, 40);

$success: $primary;
$success-light: lighten($success, 10);
$success-dark: darken($success, 40);
