@import "../colors";
@import "platforms";

.features {
  margin-left: auto;
  margin-right: auto;

  align-items: stretch;

  .sidebar {
    flex: 1 1 40%;
    border: 1px solid $mid-grey;
    padding: 0;

    background: $pale;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    .display {
      flex: 0 0 auto;
      width: 100%;
      margin: 0;

      border-bottom: 4px solid $primary;
    }

    .listing {
      flex: 1 1 auto;

      margin: 0;
      padding: 1em;
      padding-top: 1.5em;
      padding-left: 3em;
      list-style-position: outside;
    }
  }

  .text-container {
    flex: 1 1 60%;
  }
}

@include desktop {
  .features {
    width: 70%;

    .sidebar {
      border-radius: 4px;
      box-shadow: 2px 2px 8px $mid-grey;
    }
  }
}

@include mobile {
  .features {
    .sidebar {
      border-left: none;
      border-right: none;

      order: 1;
      img {
        max-height: 300px;
        object-fit: cover;
      }
    }
  }
}
