@import "colors";
@import "helpers/button";

td,
th {
  border: 1px solid #999;
  padding: 0.5rem;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
table {
  border-spacing: 0.5rem;
  border-collapse: collapse;
  width: 100%;
}

@mixin desktop {
  @media screen and (min-width: 1001px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 1000px) {
    @content;
  }
}

.navigation-container {
  width: 100%;
  height: 100%;

  .navigation-item {
    width: 100%;
    max-width: 1000px;

    margin-left: auto;
    margin-right: auto;

    position: relative;

    .ChemAccess-element {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      border: 1px solid $grey;
      color: black;
      text-decoration: none;

      .svg {
        width: 100%;
        height: 100%;
        svg {
          width: 100%;
          height: 100%;
          max-width: 1000px;
          max-height: 500px;
        }
      }
    }

    .cacc-message {
      position: absolute;
      left: 1em;
      bottom: 0;
    }

    .cacc-controls {
      padding: 0;
      position: absolute;
      right: 1em;
      top: 0;

      li {
        @include button;
        padding: 0.5em;
        text-align: center;

        display: block;
      }
      li + li {
        margin-top: 10px;
      }
    }
  }
  .navigation-controls {
    background: $pale;

    display: flex;
    order: 2;
    flex-direction: column;

    padding-top: 16px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 32px;

    color: black;
    text-decoration: none;

    box-shadow: 0 2px 8px $mid-grey;
  }
}
