@import "platforms";

.services {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  padding-left: 2em;
  padding-right: 2em;

  gap: 16px;

  .service-item {
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 2em;
    padding-bottom: 2em;
    padding-left: 1em;
    padding-right: 1em;

    background-color: $pale;
    border: 1px solid $mid-grey;
    border-radius: 4px;
    box-shadow: 2px 2px 8px $mid-grey;

    text-align: center;

    .icon {
      width: 100%;
      font-size: 3em;
    }

    .title {
      font-size: 1.2em;
      font-family: "Montserrat", sans-serif;
      margin-bottom: 0;
    }

    .summary {
      flex: 1 1 auto;
      font-size: 0.9em;
      font-family: "Work Sans", sans-serif;
      line-height: 1.5em;
      letter-spacing: 0.02em;
    }
  }
}

.services-contact {
  width: 100%;
  text-align: center;

  margin-top: 2em;

  .title {
    font-size: 1.2em;
  }

  .button {
    margin-left: 1em;
  }
}

@include mobile {
  .services {
    grid-template-columns: 1fr;

    .service-item {
      padding-top: 1em;
      padding-bottom: 1em;

      .icon {
        display: none;
      }
    }
  }
}
