@mixin grower {
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }
}

.grower {
  @include grower;
}
