.shortcode-flex-container {
  display: flex;
  align-items: center;

  flex-direction: row;
  gap: 2em;

  @include mobile {
    gap: 0.5em;
    flex-direction: column;
  }

  .shortcode-flex-fixed {
    flex: 0 0 auto;
  }

  .shortcode-flex-grow {
    flex: 1 1 auto;
  }
}
