@mixin plain {
  background: $mid-grey !important;
  box-shadow: 2px 2px 5px $grey !important;
}

@mixin danger {
  background: $error !important;
  box-shadow: 2px 2px 5px $error-light !important;
}

@mixin success {
  background: $success !important;
  box-shadow: 2px 2px 5px $success-light !important;
}

@mixin message {
  font-family: inherit;

  padding: 0.6em 1em;
  margin-top: 0.8em;
  margin-bottom: 0.8em;
  white-space: pre-wrap;

  &:empty {
    display: none;
  }

  &.error {
    @include danger;
  }
  &.success {
    @include success;
  }
}
