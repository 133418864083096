@mixin desktop {
  @media screen and (min-width: 1001px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 1000px) {
    @content;
  }
}
