@import "helpers/messages";

.toast-container {
  position: absolute;
  z-index: 2000;
  height: 100%;

  pointer-events: none;

  .toast-slider {
    position: relative;
    height: 100%;
    display: flex;
    align-items: flex-end;
  }

  .toast-slide {
    width: 100%;
    position: sticky;
    bottom: 1em;
  }

  box-sizing: border-box;

  @include desktop {
    top: 0;
    right: 2em;
    padding-bottom: 1em;

    width: 30vw;
    min-width: 400px;
  }
  @include mobile {
    bottom: 1em;

    width: 100%;
    padding-left: 1em;
    padding-right: 1em;
  }
}

.toast {
  @include message;

  --toast-fade-in: 400ms;
  --toast-fade-out: 200ms;
  --toast-stay: 6000ms;

  position: relative;
  pointer-events: initial;

  .title {
    font-weight: bold;
    display: block;
  }

  .text {
    display: block;
  }

  .delete {
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
  }

  animation: toast-fade-in var(--toast-fade-in) forwards;
  &.deleting {
    animation: toast-fade-out var(--toast-fade-out) forwards;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;

    width: 0%;
    height: 2px;

    animation-name: toast-fade-in-bar;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-duration: var(--toast-stay);
    animation-delay: var(--toast-fade-in);
  }
  &.error:after {
    background-color: $error-dark;
  }
  &.success:after {
    background-color: $success-dark;
  }
}

@keyframes toast-fade-in {
  from {
    opacity: 0;
    transform: translateX(50%);
  }
  to {
    opacity: 100;
    transform: none;
  }
}

@keyframes toast-fade-in-bar {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@keyframes toast-fade-out {
  from {
    opacity: 100;
  }
  to {
    opacity: 0;
  }
}
