@import "../colors";
@import "platforms";

.partners {
  .chart {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .partner {
      @include grower;

      display: flex;
      flex-direction: column;

      border: 1px solid $grey;
      margin: 10px;
      padding: 20px;

      background: white;
      box-shadow: 0 0 4px $mid-grey;

      border-radius: 4px;

      text-align: center;
      text-decoration: none;
      color: inherit;

      .name {
        margin: 0;
        font-weight: 600;
      }

      .image {
        flex: 1 1 auto;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;

        width: 90%;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .subtitle {
        margin: 0;
        font-weight: 400;
      }
    }
  }
}

@include desktop {
  .partners {
    .chart {
      max-width: 500px;
      margin-right: 20px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}

@include mobile {
  .partners {
    flex-direction: column;

    .chart {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
