@import "colors";

footer {
  margin: 0;

  width: 100%;
  background-color: $primary;
  padding-top: 16px;
  padding-bottom: 16px;

  z-index: 10;

  box-shadow: 0 -2px 4px $primary-darker;

  text-align: center;

  nav {
    width: 80%;
    max-width: 1000px;

    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;

    display: flex;
    align-items: center;

    a {
      text-decoration: none;
    }

    .links {
      flex: 1 1 auto;

      margin: 0;
      padding: 0;
      list-style-type: none;

      display: inline;

      font-size: 1.1em;

      li {
        display: inline-block;

        font-weight: 600;
        font-variant: small-caps;
        text-transform: lowercase;

        color: $primary-darkest;

        a {
          color: inherit;
        }
      }

      li + li {
        padding-left: 0.8em;
      }
    }
  }

  .notice {
    font-style: italic;
    font-size: 0.8em;
    font-weight: bold;

    color: $primary-darkest;
  }
}

@media screen and (max-width: 800px) {
  footer {
    nav {
      .links {
        font-size: 0.9em;
      }
    }
  }
}
