@import "../colors";
@import "platforms";

$slant: 4vw;
$border-size: 1px;
$offset: calc(#{$slant} + #{$border-size});

section.slant {
  background-color: $pale;

  position: relative;
  clip-path: polygon(0 0, 100% $slant, 100% 100%, 0 calc(100% - #{$slant}));

  &:before,
  &:after {
    content: "";
    position: absolute;
    display: block;

    width: 100%;
    height: $offset;

    background-color: $grey;
    z-index: -1000;
  }

  &:before {
    top: 0;
    clip-path: polygon(0 0, 100% $border-size, 100% $offset, 0 $border-size);
  }

  &:after {
    bottom: 0;
    clip-path: polygon(0 0, 100% $slant, 100% $offset, 0 $border-size);
  }
}

// mini hack to get slants to play nicely with final sections
section.slant + section.final {
  margin-top: -$slant;
}

@include desktop {
  section {
    margin-top: 4em;
  }

  section.slant {
    border-left: 4px solid $primary;
    padding-top: 6em;
    padding-bottom: 6em;
  }

  section.hero-container {
    margin-top: 8em;
  }
}

@include mobile {
  section {
    margin-top: 2em;
  }

  section.slant {
    padding-top: 2em;
    padding-bottom: 4em;

    .squash {
      margin-top: -4em;
    }
  }
}

section {
  > * {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }

  .section-content {
    display: flex;

    > * {
      flex: 1 1 auto;
    }
  }

  > .header {
    text-align: center;
    font-size: 2em;
    text-shadow: 0 0 5px white, 0 0 5px white;
  }
}

@include mobile {
  .section-content {
    flex-direction: column;
  }
}

.final {
  overflow: hidden;
  border-bottom: none;

  position: relative;

  .display {
    position: absolute;

    max-width: 100% !important;
    width: 100%;
    height: 100%;
    object-fit: cover;

    opacity: 0.88;
    transform: scale(1.2);
    filter: blur(4px);
  }

  .section-content {
    width: 100%;
    height: 100%;
    position: relative;

    margin-top: 100px;
    margin-bottom: 100px;

    width: 60%;
    margin-left: auto;
    margin-right: auto;

    border-radius: 0 4px 4px 0;
    border: 1px solid $grey;
    background-color: $pale;

    padding-bottom: 48px;

    border-left: 4px solid $primary;

    .section {
      flex: 1 1 60%;
    }

    .section:last-child {
      text-align: left;
      flex: 1 1 40%;
    }
  }
}

@include desktop {
  .final {
    .content-container {
      padding-bottom: 20px;
    }

    .section + .section {
      margin-left: 54px;
    }
  }
}
