@import "colors";

@mixin desktop {
  @media screen and (min-width: 801px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 800px) {
    @content;
  }
}

header {
  position: fixed;
  top: 0;
  width: 100vw;
  margin: 0;
  z-index: 10000;

  .bar {
    width: 100%;
    background-color: $primary;
    box-shadow: 0 2px 4px $primary-darker;

    padding-top: 16px;
    padding-bottom: 16px;

    nav {
      padding-left: 1.8em;
      padding-right: 1.8em;
      max-width: 1200px;

      margin-left: auto;
      margin-right: auto;

      display: flex;
      align-items: flex-end;

      .hamburger {
      }

      .info {
        flex: 0 0 auto;

        font-size: 1.3em;
        font-weight: 600;

        color: black;

        img {
          height: 1em;
          margin-right: 0.25em;
        }
      }
      .desktop-links {
        &.desktop-links-left {
          flex: 1 1 auto;
          text-align: left;
        }
        &.desktop-links-right {
          text-align: right;
        }

        font-size: 0.9em;
        transform: translateY(0.1em);
      }

      a {
        text-decoration: none;
        color: inherit;
      }
    }
  }
}

@include desktop {
  header {
    .hamburger {
      display: none;
    }

    .hamburger-menu {
      display: none;
    }
  }
}

@include mobile {
  header {
    .bar {
      nav {
        width: 100%;
        padding-left: 1em;
        padding-right: 1em;
        box-sizing: border-box;

        .info {
          flex: 1 1 auto;
          text-align: right;
        }
        .desktop-links {
          display: none;
        }
      }
    }
  }
}

.sidebar {
  position: relative;

  .hamburger-menu {
    position: absolute;

    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;

    pointer-events: none;

    transition: background-color 0.2s ease;
    background-color: #0000;

    .mobile-links {
      background: $pale;
      border-right: 2px solid $primary;
      box-shadow: 2px 0 4px $primary-dark;

      width: 70%;
      height: 100%;
      margin: 0;
      padding: 0;

      padding-top: 0.2em;

      list-style: none;

      font-size: 1.2em;

      transition: transform 0.2s ease;
      transform: translateX(-105%);

      .link {
        display: block;
        padding: 0.5em;
        margin: 0;
        padding-left: 1em;
        border-bottom: 1px solid $grey;
      }
    }

    &.visible {
      background-color: #555a;
      pointer-events: all;

      .mobile-links {
        transform: none;
      }
    }
  }
}

// HACK
//
// So because of reasons, apparently, having the header as sticky just doesn't
// like working "reasonably", creating lots of extra space at the bottom of
// the page where the sidebar would go, or causing the header to disappear
// halfway down the page, etc, etc.
//
// To fix this, we just don't make it sticky and hack it with fixed instead.
// Because of that, we also need to change the main padding, so as to not have
// an overlap with the header.

main {
  padding-top: 3.5em !important;
}
