@import "../colors";
@import "../helpers/grower";

@import "./platforms";
@import "./section";
@import "./hero";
@import "./services";
@import "./partners";
@import "./features";
@import "./preview";

.text-container {
  align-self: center;

  padding-top: 2em;
  padding-bottom: 2em;
  padding-left: 3em;
  padding-right: 5em;

  font-size: 1.1em;
  line-height: 1.5em;
  letter-spacing: 0.02em;

  p + p {
    margin-top: 2em;
  }
}

@include desktop {
  .text-container {
    &.centered {
      text-align: center;
    }
  }
}

@include mobile {
  .text-container {
    padding-left: 1.8em;
    padding-right: 1.8em;
  }
}

.continue {
  display: block;
  text-align: center;

  margin-top: 1em;

  font-weight: 400;
  color: black;
  text-decoration: none;
}

.text-display {
  .display {
    flex: 1 0 auto;
    border-radius: 4px;
  }
}

@include desktop {
  .text-display {
    .display {
      order: 1;
      width: 50%;
      max-width: 800px;
      max-height: 500px;
      object-fit: cover;

      box-shadow: 2px 2px 8px $dark-grey;
    }
  }
}

@include mobile {
  .text-display {
    .display {
      order: -1;
      width: 100%;
      height: 400px;
      object-fit: cover;
    }
  }
}
