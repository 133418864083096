.chemistry-preview {
  display: flex;

  .images {
    .buttons {
      width: 100%;
      display: flex;
      position: relative;
      z-index: 1000;

      #left,
      #right {
        display: inline-block;
      }

      #left {
        &::after {
          content: "<";
        }
      }
      #right {
        margin-left: auto;
        &::after {
          content: ">";
        }
      }
    }

    .image-stack {
      width: 100%;

      .image {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
        }
      }

      .image-caption {
        position: absolute;
        z-index: 10000;

        bottom: 3.5em;
        left: 10%;
        width: 80%;

        text-align: center;
        font-size: 0.8em;
        line-height: 1.4em;

        background-color: $pale;
        box-shadow: 0 0 5px 5px $pale;

        &:empty {
          display: none;
        }
      }
    }
  }
}

@include desktop {
  .chemistry-preview {
    .sidebar {
      flex: 1 1 60%;
      > * {
        margin-top: 6em;
      }
      margin-bottom: 4em;
    }

    .images {
      flex: 1 1 40%;

      display: flex;
      margin-top: 2em;
      margin-bottom: 2em;

      .buttons {
        display: none;
      }

      .image-stack {
        transform: scale(1.2);
        align-self: flex-start;
        position: sticky;
        top: 40vh;

        .image-shadow {
          z-index: 10000;
          position: absolute;
          top: -2px;
          left: 0px;
          width: 100%;
          height: 100%;
          padding: 0;
          margin: 0;
          box-sizing: border-box;
          box-shadow: 0 0 15px 15px $pale inset;
        }

        .image {
          &[data-active="true"] {
            opacity: 1;
            transition: 0.2s ease-out opacity;
            img {
              box-shadow: 0 0 8px 8px black inset;
            }
          }
          &[data-active="false"] {
            opacity: 0;
            transition: 0.2s ease-in opacity;
          }
        }

        .image + .image {
          // this keeps the first image as a relative position
          // since all the images *should be* the same size, the image stack
          // stays the right size
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }
}

@include mobile {
  .chemistry-preview {
    flex-direction: column;

    .sidebar {
      .text-container {
        // yes, these have been kinda hardcoded
        margin-left: 1.7em;
        margin-right: 1.6em;
        border: 1px solid $dark-grey;
        border-bottom: none;

        background-color: lighten($pale, 3);

        &[data-active="true"] {
        }
        &[data-active="false"] {
          display: none;
        }
      }
    }

    .images {
      margin-left: 30px;
      margin-right: 30px;

      .image-stack {
        position: relative;

        img {
          border: 1px $dark-grey solid;
        }

        .image {
          position: relative;

          &[data-active="true"] {
            opacity: 1;
            transition: 0.2s ease-out opacity;
            img {
              box-shadow: 0 0 8px 8px black inset;
            }
          }
          &[data-active="false"] {
            opacity: 0;
            transition: 0.2s ease-in opacity;
          }
        }
        .image + .image {
          // same as above!
          position: absolute;
          left: 0;
          top: 0;
        }

        .image-shadow {
          display: none;
        }
      }
    }
  }
}
