@import "../colors";

@mixin button {
  position: relative;
  z-index: 21;
  display: inline-block;

  background: $primary;
  padding: 20px;
  font-family: "Work Sans", sans-serif;
  font-size: 1em;
  font-weight: 500;
  text-decoration: none;
  outline: none;

  color: inherit;
  box-shadow: 2px 2px 5px $primary-darker;

  outline: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:visited {
    color: inherit;
  }

  &.mini {
    padding-top: 0.8em;
    padding-bottom: 0.8em;
  }
}

@mixin button-joiner {
  position: relative;
  z-index: 20;

  @include desktop {
    display: inline-block;

    background: $primary-light;
    padding: 20px;
    font-family: "Work Sans", sans-serif;
    font-weight: 500;
    text-decoration: none;

    color: inherit;
    box-shadow: -1px 2px 5px $primary-darker;

    border-radius: 40px;

    &:visited {
      color: inherit;
    }

    &.mini {
      padding-top: 0.8em;
      padding-bottom: 0.8em;
    }
  }
}

@mixin button-joiner-left {
  margin-right: 10px;
  @include desktop {
    margin-right: -24px;
    padding-right: 36px;
  }
}

@mixin button-joiner-right {
  margin-left: 10px;
  @include desktop {
    margin-left: -24px;
    padding-left: 36px;
  }
}

@mixin button-joiner-center {
  @include button-joiner-left;
  @include button-joiner-right;
}
