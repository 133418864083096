.columns {
  display: grid;
  grid-template-columns: 1fr;
}
.columns-flex {
  display: flex;
  justify-content: space-between;
}

.column {
  &.align-right {
    @include desktop {
      text-align: right;
    }
  }
}

@include desktop {
  .columns {
    @for $i from 1 through 4 {
      &.columns-#{$i} {
        grid-template-columns: repeat($i, 1fr);
      }
    }
    gap: 20px;
  }
}
