@import "platforms";

.hero {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;

  .center {
    display: flex;
    align-items: center;

    .logo {
      flex: 0 0 auto;
      width: 300px;
      box-shadow: 0 0 10px 10px white;
    }

    .text {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .rest {
    margin-top: 2em;
    text-align: center;
  }

  .header {
    width: 100%;
    flex: 0 1 auto;
    margin: 0;
    font-size: 2.5em;
  }

  .subheader {
    width: 100%;
    font-size: 1.4em;
    font-style: italic;
    color: #777;
  }
}

@include desktop {
  .hero {
    margin-top: 2em;
    margin-bottom: 2em;

    .center {
      .logo {
        margin-right: 30px;
      }
    }
  }
}

@include mobile {
  .hero {
    margin-left: 0.8em;
    margin-right: 0.8em;
    margin-top: 2em;
    margin-bottom: 2em;

    .center {
      flex-direction: column;

      .logo {
        margin-bottom: 10px;
      }
    }

    .header,
    .subheader,
    .subsubheader {
      text-align: center;
    }

    .rest {
      margin: 0;
    }
  }
}
